<template>
  <v-app-bar
    app
    ref="navbar"
    class="white"
    clipped-left
    :height="$vuetify.breakpoint.smAndDown ? 112 : 150"
  >
    <div :class="$vuetify.breakpoint.smAndDown ? '' : 'container py-0'">
      <!-- navbar -->
      <div class="navbar d-flex align-center flex-wrap flex-md-nowrap">
        <!-- navigazione (solo smAndDown) -->
        <div
          class="d-flex d-md-none justify-start align-center p-sm-auto category-column"
        >
          <v-btn
            v-if="$routerHistory.hasPrevious() && $platform_is_cordova"
            :to="{ path: $routerHistory.previous().path }"
            elevation="0"
            aria-label="Torna Indietro"
          >
            <v-icon large elevation="0">$chevronLeft</v-icon>
          </v-btn>
          <v-btn
            color="secondary"
            aria-label="Apri menu categorie"
            class="mobile-menu-btn"
            @click.prevent.stop="drawerLeft = !drawerLeft"
          >
            <v-icon large>$menu</v-icon>
          </v-btn>
        </div>

        <!-- logo col -->
        <div
          class="order-1 flex-grow-1 align-center align-md-left d-flex justify-center justify-md-start align-center"
        >
          <router-link :to="{ name: 'Home' }" class="logo-link">
            <!-- logo -->
            <img class="logo" src="/logo/logo_claim.png" alt="Logo Zona.eu" />
          </router-link>
        </div>
        <!-- search col -->
        <div
          class="order-4 order-md-2 d-flex px-md-8  mt-1 mt-md-0 px-4 flex-grow-1 w-100"
        >
          <SearchInput class="pr-auto pr-lg-auto" />
        </div>
        <!-- buttons col -->
        <div
          class="order-2 order-md-3 d-flex justify-end align-center pr-0 pr-md-3"
        >
          <template v-if="!isAuthenticated">
            <v-btn @click.stop="handleLogin()" medium icon class="">
              <v-icon color="default">$profile</v-icon>
            </v-btn>
            <v-divider vertical class="mx-1"></v-divider>
            <v-btn icon @click.stop="handleLogin()" class="mr-2">
              <v-icon color="default">$cart</v-icon>
            </v-btn>
          </template>
          <template v-else>
            <v-btn
              class="d-none d-md-flex bg-transparent"
              medium
              tile
              icon
              @click="openTimeslotSelector"
            >
              <!-- <img aria-hidden :src="serviceImg" /> -->
              <v-icon color="default">$calendar</v-icon>
            </v-btn>
            <transition name="bounce" mode="out-in">
              <v-badge
                :content="newsList.length"
                :value="newsList.length > 0"
                :key="newsList.length"
                transition="bounce"
                color="primary"
                overlap
                :offset-x="25"
                offset-y="25"
                class="d-md-flex d-none flex-row align-center default--text font-weight-bold cart-badge"
              >
                <v-btn
                  class="d-none d-md-flex bg-transparent"
                  medium
                  tile
                  icon
                  to="/profile/news-list"
                >
                  <v-icon color="default">$bell</v-icon>
                </v-btn>
              </v-badge>
            </transition>
            <div class="d-flex flex-row align-center mr-2 text-caption">
              <v-menu bottom offset-y nudge-bottom="2" tile min-width="250">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on.stop="on"
                    medium
                    depressed
                    :icon="!$vuetify.breakpoint.lgAndUp"
                    color="transparent"
                  >
                    <v-icon color="secondary">$profileFull</v-icon>
                    <span class="d-none d-lg-inline-block align-left welcome">
                      Ciao <br />
                      {{ cart.user.firstName }}
                    </span>
                  </v-btn>
                </template>
                <UserMenu :user="cart.user" />
              </v-menu>
              <v-divider vertical class="mx-1"></v-divider>
              <transition name="bounce" mode="out-in">
                <v-badge
                  :content="totalItems"
                  :value="totalItems > 0"
                  :key="totalItems"
                  transition="bounce"
                  color="primary"
                  overlap
                  :offset-x="$vuetify.breakpoint.mdAndDown ? 22 : 90"
                  offset-y="18"
                  class="d-flex flex-row align-center default--text font-weight-bold cart-badge"
                >
                  <v-btn
                    aria-label="Apri carrello"
                    medium
                    :icon="!$vuetify.breakpoint.lgAndUp"
                    depressed
                    color="transparent"
                    @click.stop="openCart()"
                    class="cart-btn"
                  >
                    <v-icon color="default">$cart</v-icon>
                    <div
                      class="d-none d-lg-inline-block align-left cart-total"
                      v-html="
                        `<b class='default--text'> ${$n(
                          cart.totalPrice,
                          'currency'
                        )} </b>`
                      "
                    ></div>
                  </v-btn>
                </v-badge>
              </transition>
            </div>
          </template>
        </div>
      </div>
    </div>
    <v-divider class="grey w-100 mb-1" />
    <div class="container py-0">
      <LinksMenu v-if="$vuetify.breakpoint.mdAndUp" :items="primaryLinks" />
    </div>
    <v-progress-linear
      arial-label="Caricamento in corso"
      :active="showOverlay"
      :indeterminate="showOverlay"
      absolute
      bottom
      color="primary"
    ></v-progress-linear>
  </v-app-bar>
</template>
<style lang="scss">
.navbar {
  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    .v-badge__wrapper {
      span {
        inset: auto auto calc(100% - 15px) calc(100% - 105px) !important;
      }
    }
  }
  .cart-badge {
    .cart-total {
      position: relative;
      left: 8px;
    }
  }
}
</style>
<script>
import SearchInput from "@/components/navigation/SearchInput.vue";
import UserMenu from "@/components/navigation/UserMenu.vue";
// import Login from "@/components/Login.vue";
import LinksMenu from "@/components/navigation/LinksMenu.vue";

import login from "~/mixins/login";
import cartInfo from "~/mixins/cartInfo";
import clickHandler from "~/mixins/clickHandler";

import newsService from "~/service/newsService";

// import get from "lodash/get";
import { mapActions, mapState } from "vuex";

export default {
  name: "Navbar",
  mixins: [login, clickHandler, cartInfo],
  components: {
    SearchInput,
    LinksMenu,
    UserMenu
    // Login
  },
  props: {
    primaryLinks: { type: Array, required: false },
    applicationConfig: { type: Object, required: false }
  },
  data() {
    return {
      newsList: []
    };
  },
  mounted() {
    if (this.isAuthenticated) {
      this.checkNews();
    }
    global.EventBus.$on("updateNewsList", newsList => {
      this.newsList = newsList;
    });
  },
  methods: {
    ...mapActions({
      doLogout: "cart/doLogout",
      updatedDrawerLeft: "app/updatedDrawerLeft",
      updatedDrawerRight: "app/updatedDrawerRight",
      updatedDrawerLinks: "app/updatedDrawerLinks"
    }),
    closeLogin() {
      this.$refs.loginMenuRef.isActive = false;
    },
    goBack() {
      this.$router.go(-1);
    },
    openCart() {
      if (this.editableCart) {
        if (this.isAuthenticated) {
          if (this.$vuetify.breakpoint.xs && this.editableCart) {
            this.$router.push("/checkout");
          } else {
            this.drawerRight = !this.drawerRight;
          }
        } else {
          this.doLogin("OpenCart");
        }
      } else {
        this.handleLogin();
      }
    },
    async checkNews() {
      this.newsList = await newsService.list(false);
    },
    async handleLogin() {
      if (await this.needLogin("navbar")) {
        if (await this.needAddress()) {
          await this.needTimeslot();
        }
      }
    },
    historyBack() {
      window.history.back();
    }
  },
  computed: {
    ...mapState({
      storeDrawerLeft: state => state.app.drawerLeft,
      storeDrawerRight: state => state.app.drawerRight,
      storeDrawerLinks: state => state.app.drawerLinks,
      cart: ({ cart }) => cart.cart
    }),
    drawerLinks: {
      get() {
        return this.storeDrawerLinks;
      },
      set(value) {
        this.updatedDrawerLinks(value);
      }
    },
    enableRegistration() {
      return global.config.registration;
    },
    totalItems() {
      return this.cart.totalItems;
    },
    editableCart() {
      return !(this.$route.name == "Payment");
    },
    showOverlay() {
      return this.$store.state.app.pendingRequests.count > 0;
    },
    drawerLeft: {
      get() {
        return this.storeDrawerLeft;
      },
      set(value) {
        this.updatedDrawerLeft(value);
      }
    },
    drawerRight: {
      get() {
        return this.storeDrawerRight;
      },
      set(value) {
        this.updatedDrawerRight(value);
      }
    },
    availablePoints() {
      //TODO: verificare mappatura dei campi
      return 100;
    }
  }
};
</script>

<style scoped lang="scss">
.primary-links {
  height: 36px;
}
.category-column {
  width: 70px;
}
.navbar {
  background-color: $white;
  height: 95px;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    height: 112px;
    box-shadow: 0px 2px 13pt #0000004d;
  }
}
.welcome {
  text-align: left;
  font-size: 12px;
  line-height: 1;
  color: var(--v-secondary-base);
  text-transform: capitalize;
}

.logo-link {
  max-height: 70px;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    max-height: 40px;
  }
  .logo {
    max-height: 70px;
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      max-height: 40px;
    }
  }
}
@media #{map-get($display-breakpoints, 'md-and-up')} {
  .v-badge.cart-badge {
    color: yellow !important;
    .v-badge__badge {
      left: -20px;
      top: -6px;
      right: initial;
    }
  }
}
.v-badge__wrapper {
  background-color: blue;
  left: -20px;
  top: -6px;
}
.mobile-menu-btn {
  margin-right: 48px;
  &::v-deep.v-btn {
    padding: 0;
    width: 45px;
    height: 40px;
    min-width: 45px;
    margin-left: 16px;
  }
}

::v-deep .v-toolbar__content {
  display: block;
  padding: 0px;
  transition: height 0.2s ease;
  -webkit-transition: height 0.2s ease;
}
</style>
