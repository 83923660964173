<template>
  <!-- eslint-disable vue/no-use-v-if-with-v-for -->
  <div class="navbar-menu d-flex flex-row align-center">
    <v-btn
      color="secondary"
      aria-label="Apri menu categorie"
      @click.prevent.stop="drawerLeft = !drawerLeft"
    >
      <v-icon>$menu</v-icon>
      {{ $t("navbar.products") }}
    </v-btn>

    <div class="items-wrapper">
      <v-slide-group multiple show-arrows>
        <div
          v-for="(item, idx) in items"
          v-bind:key="idx"
          class="mx-2 mx-md-3 d-flex justify-center align-center"
        >
          <a
            v-if="
              item.templateNavItems != undefined &&
                item.templateNavItems.length === 0
            "
            class="px-1 pl-md-3 text-decoration-none"
            :class="item.classes"
            :href="item.itemUrl"
            @click="clicked"
          >
            {{ item.name }}
          </a>

          <v-menu
            v-else
            bottom
            offset-y
            x
            content-class="children-links-menu py-1"
          >
            <template v-slot:activator="{ on, attrs }">
              <a
                :id="`header-menu-${item.templateNavItemId}`"
                v-bind="attrs"
                v-on.stop="on"
                class="px-1 px-lg-3"
                :class="item.classes"
              >
                <span class="mr-1">{{ item.name }}</span>
                <v-icon
                  color="default"
                  v-if="item.templateNavItems?.length > 0"
                >
                  $chevronDown
                </v-icon>
              </a>
            </template>
            <div>
              <div v-for="(child, idx2) in item.templateNavItems" :key="idx2">
                <a
                  class="text-body-2 default--text d-block w-100 nav-link pa-2"
                  :class="child.classes"
                  :href="child.itemUrl"
                  @click="clicked"
                >
                  {{ child.name }}
                </a>
                <div class="d-flex flex-column">
                  <a
                    v-for="(child2, idx3) in child.templateNavItems"
                    :key="idx3"
                    :href="child2.itemUrl || '#'"
                    class="text-body-2 default--text my-1 d-block w-100 nav-link pa-2"
                    :class="child2.classes"
                    :target="child2.target || '_self'"
                    @click="clicked"
                  >
                    <span :class="child2.classes">{{ child2.name }}</span>
                  </a>
                </div>
              </div>
            </div>
          </v-menu>
        </div>
      </v-slide-group>
    </div>
  </div>
  <!-- eslint-enable vue/no-use-v-if-with-v-for -->
</template>

<script>
import { mapActions } from "vuex";
import clickHandler from "~/mixins/clickHandler";
export default {
  name: "LinksMenu",
  props: {
    items: { type: Array, required: true }
  },
  mixins: [clickHandler],

  computed: {
    drawerLeft: {
      get() {
        return this.$store.state.app.drawerLeft;
      },
      set(value) {
        this.updatedDrawerLeft(value);
      }
    }
  },
  methods: {
    ...mapActions({
      updatedDrawerLeft: "app/updatedDrawerLeft"
    })
  }
};
</script>

<style scoped lang="scss">
.navbar-menu {
  height: $navbar-menu-height;
  .drawer-button {
    height: $navbar-menu-height;
    min-width: $drawer-btn-width;
  }
  .items-wrapper {
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
    overflow-y: hidden;
    white-space: nowrap;
    height: 45px;
  }

  a {
    color: var(--v-default-base) !important;
    line-height: 2;
  }
}
.children-links-menu {
  background-color: white;
  top: 150px !important;
  max-width: 100%;
  border-radius: 12px 12px 12px 0px !important;
  z-index: 10 !important;
  .v-menu__content {
    box-shadow: 0px 3px 6px #00000029;
  }
  a {
    text-decoration: none;
  }
  .v-card {
    &.yellow-gradient {
      background-color: #dda801;
      background: linear-gradient(
        0deg,
        rgba(2, 0, 36, 1) 0%,
        rgba(191, 145, 6, 1) 28%,
        rgba(205, 156, 4, 1) 39%,
        #dda801 44%
      );
    }
    &.transparent-gradient {
      .v-card__title,
      .v-card__subtitle {
        z-index: 2;
      }
      .gradient-layer {
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, black, transparent);
      }
    }
  }
  .nav-link:hover {
    background-color: var(--v-grey-lighten1);
  }
}
</style>
